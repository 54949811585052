import React from 'react';
import { Figure } from 'react-bootstrap';
import team1 from "./images/team-1.jpg";

function About() {
  return (
    <Figure id="about">
      <h1 className="about-header">About ProCare</h1>
      <Figure.Image
        width={600}
        height={600}
        alt="procare-team-photo"
        src={team1}
      />
      <Figure.Caption>
        We BELIEVE in prioritizing our valuable time to afford the flexibility of choice in our turbulent schedules.
        We TRUST that this concept helps Bay Area clinicians generate additional income within minutes from
        their own home or current workplace eliminating the need to sacrifice valued weekends and other
        priorities.  As a result, our business model creates a company CULTURE of increased freedom of time,
        flexibility in schedule, and slow nurtured introduction to a booming career in the home health industry.
      </Figure.Caption>
    </Figure>
  );
}

export default About;
