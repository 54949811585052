import React from 'react';
import iStock0 from "./images/iStock-0.jpg";

function MobileLanding() {
  return (
    <div className="mobile-view">
      <img
        className="d-block w-100"
        src={iStock0}
        alt="Mobile Landing" />
    </div>
  );
}

export default MobileLanding;
