import React from 'react';
import { Carousel } from 'react-bootstrap';
import iStock1 from "./images/iStock-1.png";
import iStock2 from "./images/iStock-2.jpg";
import iStock3 from "./images/iStock-3.jpg";
import iStock5 from "./images/iStock-5.jpg";

function Carousels() {
  return (
    <div>
      <Carousel className="Carousel">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={iStock1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h4 className="carousel-caption">Opportunity is closer than you think!</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={iStock2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={iStock3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={iStock5}
            alt="Fifth slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Carousels;
