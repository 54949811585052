import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Landing.css';
import NavigationBar from './NavigationBar';
import Carousels from './Carousels';
import About from './About';
import Cards from './Cards';
import Affiliates from './Affiliates';
import Footer from './Footer';
import MobileLanding from './MobileLanding';

function Landing() {
  return (
    <div>
      <header id="landing">
        <NavigationBar />
      </header>
      <Carousels />
      <MobileLanding />
      <About />
      <Cards />
      <div className="container" >
        <hr id="section-divider"></hr>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSee8ACOmfNazeJvh54osyuuK5mWdhPYcnhyT_RNFjyODDezkw/viewform?embedded=true" title="google-form" frameBorder="0" marginHeight="0" marginWidth="0" className="google-form">Loading…</iframe>
      </div>
      <Affiliates />
      <Footer />
    </div>
  );
}

export default Landing;
