import React from 'react';
import { Container, CardDeck, Card } from 'react-bootstrap';
import iStock3card from "./images/iStock-3-card.jpg";
import iStock4card from "./images/iStock-4-card.jpg";
import iStock6card from "./images/iStock-6-card.jpg";
import homes1 from "./images/homes-1.jpg";

function Cards() {
  return (
    <div>
      <Container>
        <CardDeck className="card-deck">
          <Card border="primary">
            <Card.Img variant="top" src={iStock3card} />
            <Card.Body>
              <Card.Title>JOIN HOME CARE NOW</Card.Title>
              <Card.Text className="card-text">
                Looking for:
                <ul>
                  <li>Physical Therapist</li>
                  <li>Occupational Therapists</li>
                  <li>Speech Therapists</li>
                </ul>
                You can serve your local community NOW!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Img variant="top" src={homes1} />
            <Card.Body>
              <Card.Title>CHOOSE YOUR TERRITORY</Card.Title>
              <Card.Text className="card-text">
                Free to identify only the areas YOU want to cover!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Img variant="top" src={iStock4card} />
            <Card.Body>
              <Card.Title>COMPETITIVE COMPENSATION</Card.Title>
              <Card.Text className="card-text">
                For referrals within minutes from home - Service your own neighborhood!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card border="primary">
            <Card.Img variant="top" src={iStock6card} />
            <Card.Body>
              <Card.Title>PROCARE YOUTUBE VIDEO</Card.Title>
              <Card.Text>
                <a href="https://www.youtube.com/watch?v=ZziTf4z_pCg&feature=youtu.be">ProCare Staffing Agency V5</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
      </Container>
    </div>
  );
}

export default Cards;