import React from 'react';
import navLogo from "./images/logo-transparent.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img
          src={navLogo}
          width="250"
          height="75"
          className="d-inline-block align-top"
          alt="ProCare Staffing Logo"
        />
      </div>
      <div className="footer-content">
        <div className="footer-element">
          <h4>Follow Us</h4>
          <ul className="list-unstyled">
            <li><i className="fab fa-facebook-square"></i><a href="https://www.facebook.com/procarestaff/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><i className="fab fa-instagram-square"></i><a href="https://www.facebook.com/procarestaff/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><i className="fab fa-twitter-square"></i><a href="https://www.facebook.com/procarestaff/" target="_blank" rel="noopener noreferrer">Twitter</a></li>
          </ul>
        </div>
        <div className="footer-element">
          <h4>About</h4>
          <ul className="list-unstyled">
            <li><i className="fas fa-users fa-xs"></i><a href="#about">About</a></li>
          </ul>
        </div>
        <div className="footer-element">
          <h4>Email Us</h4>
          <ul className="list-unstyled">
            <li>
              <i className="fas fa-envelope-square"></i>
              <a href="mailto:info@procarestaffingagency.com" target="_blank" rel="noopener noreferrer">Email</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;