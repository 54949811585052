import React from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import navLogo from "./images/logo-transparent.png";

function NavigationBar() {
  return (
    <div>
      <header>
        <Navbar expand="lg" fixed="top" className="navigation-bar">
          <Navbar.Brand href="#home">
            <img
              src={navLogo}
              width="100"
              height="30"
              className="d-inline-block align-top"
              alt="ProCare Staffing Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#landing">Home</Nav.Link>
              <Nav.Link href="#about">About Us</Nav.Link>
              <NavDropdown title="Forms" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://drive.google.com/file/d/1TAxVhpUZIXG7l3-qPLsvqRyX176qErlQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-item">Route Sheet</NavDropdown.Item>
                <NavDropdown.Item href="https://drive.google.com/file/d/1HncQ4i3tpt28j1UF1YgY-6frlc_0N6FE/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-item">Clinician Invoice</NavDropdown.Item>
                <NavDropdown.Item href="https://drive.google.com/file/d/1Wf8hJaPinjMGYIPvKGPQIu_fC_QqdM6z/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-item">Employment Application</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="https://drive.google.com/drive/folders/1HuHk8LfqqERfKz04Y5zcHQD-Pj5VxLO7?usp=sharing" target="_blank" rel="noopener noreferrer" className="nav-item">All Forms</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Button variant="outline-light" href="#section-divider" className="contact-button">CONTACT US!</Button>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </div>
  );
}

export default NavigationBar;
