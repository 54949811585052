import React from 'react';
import affiliate1 from "./images/af-1.jpg";
import affiliate2 from "./images/af-2.jpg";
import affiliate3 from "./images/af-3.jpg";
import affiliate4 from "./images/af-4.jpg";
import affiliate5 from "./images/af-5.jpg";
import affiliate6 from "./images/af-6.jpg";
import affiliate7 from "./images/af-7.jpg";
import affiliate8 from "./images/af-8.jpg";
import affiliate9 from "./images/af-9.jpg";

function Affiliates() {
  return (
    <div>
      <h1 className="affiliate-header">Affiliates</h1>
      <div className="affiliate-container">
        <div className="affiliate-col">
          <img src={affiliate1} alt="affiliate-1"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate2} alt="affiliate-2"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate3} alt="affiliate-3"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate4} alt="affiliate-4"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate5} alt="affiliate-5"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate6} alt="affiliate-6"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate7} alt="affiliate-7"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate8} alt="affiliate-8"></img>
        </div>
        <div className="affiliate-col">
          <img src={affiliate9} alt="affiliate-9"></img>
        </div>
      </div>
    </div>
  );
}

export default Affiliates;
